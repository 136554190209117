export default [
    {
        header: 'Content',
        resource: 'Blog',
        action: 'read',
     
    },
    {
      title: 'Blog',
      icon: 'BookOpenIcon',
     
      tagVariant: 'light-warning',
      resource: 'Blog',
      action: 'read',
      children: [
        {
            title: 'Blog',
            route: 'blog-read',  
        
            resource: 'Blog',
            action: 'read',
        },
        {
            title: 'Category',
            route: 'blog-category-read',  
            
            resource: 'Blog',
            action: 'read',
        },

      ]
     
     
    },
    {
        title: 'Portfolio',
        icon: 'FileIcon',
       
        tagVariant: 'light-warning',
        resource: 'Portfolio',
        action: 'read',
        children: [
          {
              title: 'Portfolio',
              route: 'portfolio-read',  
          
              resource: 'Portfolio',
              action: 'read',
          },
          {
              title: 'Category',
              route: 'portfolio-category-read',  
              
              resource: 'Blog',
              action: 'read',
          },
  
        ]
       
       
      },
      {
        title: 'Course',
        icon: 'GithubIcon',
        route: 'services-read',  
        tagVariant: 'light-warning',
        resource: 'Services',
        action: 'read',
       
       
    },
    
    {
        title: 'School',
        icon: 'FileTextIcon',
        route: 'pages-read',  
        tagVariant: 'light-warning',
        resource: 'Pages',
        action: 'read',
       
       
    },
    {
        title: 'Feedback',
        icon: 'MessageCircleIcon',
        route: 'feedback-read',  
        tagVariant: 'light-warning',
        resource: 'Feedback',
        action: 'read',
    
    
    },
    {
        title: 'Banner',
        icon: 'ImageIcon',
        route: 'banner-read',  
        tagVariant: 'light-warning',
        resource: 'Banner',
        action: 'read',
       
       
    },
   
    {
        title: 'Navigation',
        icon: 'NavigationIcon',
        route: 'navigation-read',  
        tagVariant: 'light-warning',
        resource: 'Navigation',
        action: 'read',
    
    
    },
    {
        title: 'Widget',
        icon: 'ClipboardIcon',
        route: 'widgets-read',  
        tagVariant: 'light-warning',
        resource: 'Widgets',
        action: 'read',
    
    
    },
    {
        title: 'Gallery',
        icon: 'ImageIcon',
        route: 'gallery-create',  
        tagVariant: 'light-warning',
        resource: 'Gallery',
        action: 'create',
    
    
    },

     

]