export default [
    {
        header: 'Settings',
        resource: 'Settings',
        action: 'permission',
     
    },
    {
      title: 'Users',
      icon: 'UserPlusIcon',
      route: 'user-read',  
      tagVariant: 'light-warning',
      resource: 'User',
      action: 'read',
     
    },
    {
      title: 'General',
      icon: 'SettingsIcon',
      route: 'settings-general',  
      tagVariant: 'light-warning',
      resource: 'Settings',
      action: 'general',
     
     
    },
]